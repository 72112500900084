import axios from "axios"
import { reloadProps, reloadSession } from "../../services/common/helpers"
import moment from "moment"
import TagManager from "react-gtm-module"
import handleError from "../errors/handleError"

export const handleBookmark = async (listing, session, setLoading) => {
  try {
    setLoading(true)
    if (!session?.id) {
      setLoading(false)
      return "login first"
    }

    if (session?.bookmarks?.find(item => item.uid === listing.uid)) {
      //unbookmark
      const bookmarksFiltered = session.bookmarks.filter(
        item => item.id !== listing.id
      )

      TagManager.dataLayer({
        dataLayer: {
          event: "listing_button_unbookmark",
          listing: listing?.slug,
          listing_id: listing?.uid,
          user_id: session?.user?.uid,
        },
      })

      await axios
        .put(
          "/api/users",
          {
            bookmarks: bookmarksFiltered,
          },
          {
            headers: {
              Authorization: `Bearer ${session.jwt}`,
            },
          }
        )
        .catch(error => {
          handleError(error)
        })

      setLoading(false)
      reloadSession()
    } else {
      const bookmarks = [...(session?.bookmarks || []), listing.id]

      TagManager.dataLayer({
        dataLayer: {
          event: "listing_button_bookmark",
          listing: listing?.slug,
          listing_id: listing?.uid,
          user_id: session?.user?.uid,
        },
      })

      await axios
        .put(
          "/api/users",
          {
            bookmarks: bookmarks,
          },
          {
            headers: {
              Authorization: `Bearer ${session.jwt}`,
            },
          }
        )
        .catch(error => {
          handleError(error)
        })
      setLoading(false)
      reloadSession()
    }
  } catch (err) {
    handleError(err)
    setLoading(false)
  }
}

export const fetchDiscount = async code => {
  return await axios
    .get(`/api/discounts?code=${code}`)
    .then(res => {
      if (res?.data) {
        return res?.data
      } else {
        return null
      }
    })
    .catch(() => {
      return null
    })
}

export const handleWaitingList = async (listing, session, router, update) => {
  if (!session?.id) {
    return "login first"
  }

  if (session?.waitingList?.find(item => item.uid === listing.uid)) {
    //unbookmark
    const waitingListFiltered = session.bookmarks.filter(
      item => item.id !== listing.id
    )

    TagManager.dataLayer({
      dataLayer: {
        event: "listing_button_waitlist",
        listing: listing?.slug,
        listing_id: listing?.uid,
        user_id: session?.user?.uid,
      },
    })

    await axios
      .put(
        "/api/users",
        {
          waitingList: waitingListFiltered,
        },
        {
          headers: {
            Authorization: `Bearer ${session.jwt}`,
          },
        }
      )
      .catch(error => {
        handleError(error)
      })

    update()
  } else {
    const waitingList = [...(session?.bookmarks || []), listing.id]

    await axios
      .put(
        "/api/users",
        {
          waitingList: waitingList,
        },
        {
          headers: {
            Authorization: `Bearer ${session.jwt}`,
          },
        }
      )
      .catch(error => {
        handleError(error)
      })

    update()
  }
}

export const handleNewContact = async user => {
  try {
    return await axios.post(
      "https://api.sendinblue.com/v3/contacts",
      {
        attributes: {
          LASTSEEN: moment().toDate(),
          FIRSTNAME: user?.profile?.firstName,
          LASTNAME: user?.profile?.lastName,
          TYPE: user?.accountType,
          NOTIFICATIONS: true,
          LOCALE: user?.profile?.locale || "EN",
          REGISTERED: true,
          ONBOARDING: "PROFILE",
          VERIFIED: false,
          UID: user?.uid,
          INVESTMENTVALUE: 0,
          AUTOINVEST: false,
        },
        listIds: [2],
        ext_id: user.uid,
        updateEnabled: false,
        email: user.email,
      },
      {
        headers: {
          "api-key": `${process.env.SENDINBLUE_KEY}`,
          "content-type": "application/json",
        },
      }
    )
  } catch (error) {
    handleError(error)
  }
}

export const fetchTransactionalEmails = async user => {
  return await axios
    .get(
      `https://api.brevo.com/v3/smtp/emails?email=${user?.email}&sort=desc&limit=500&offset=0`,
      {
        headers: {
          "api-key": `${process.env.SENDINBLUE_KEY}`,
          "content-type": "application/json",
        },
      }
    )
    .catch(error => {
      handleError(error)
    })
}

export const getStrapiUser = async param => {
  const url = `${process.env.STRAPI_URL}/api/users?${param}`

  const user = await axios
    .get(url, {
      headers: {
        Authorization: `bearer ${process.env.STRAPI_KEY}`,
      },
    })
    .catch(err => {
      handleError(err)
    })

  return user?.data
}

export const getStrapiUserById = async (id, param) => {
  const url = `${process.env.STRAPI_URL}/api/users/${id}?${param}`

  const user = await axios
    .get(url, {
      headers: {
        Authorization: `bearer ${process.env.STRAPI_KEY}`,
      },
    })
    .catch(err => {
      handleError(err)
    })

  return user?.data
}

export const updateUser = async (user_id, data) => {
  return await axios
    .put(`${process.env.STRAPI_URL}/api/users/${user_id}`, data, {
      headers: {
        Authorization: `bearer ${process.env.STRAPI_KEY}`,
      },
    })
    .catch(err => {
      handleError(err)
    })
}

export const updateStrapiUser = async (user_id, data) => {
  return await axios
    .put(`${process.env.STRAPI_URL}/api/users/${user_id}`, data, {
      headers: {
        Authorization: `bearer ${process.env.STRAPI_KEY}`,
      },
    })
    .catch(err => {
      handleError(err)
    })
}

export const fetchListing = async (id, params) => {
  const result = await axios
    .get(`${process.env.STRAPI_URL}/api/listings/${id}?${params}`, {
      headers: {
        Authorization: `Bearer ${process.env.STRAPI_KEY}`,
      },
    })
    .catch(error => {
      handleError(error)
    })

  return result.data
}
